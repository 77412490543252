<template>
    <el-dialog :visible.sync="visible" title="编辑固废类别" width="400px">
        <el-form :model="model" size="small" label-width="100px">
            <el-form-item label="编码">
                <el-input v-model="model.code"></el-input>
            </el-form-item>
            <el-form-item label="固废类别名称">
                <el-input v-model="model.cName"></el-input>
            </el-form-item>
            <el-form-item label="计算单位">
                <el-input v-model="model.unit"></el-input>
            </el-form-item>
        </el-form>

        <div class="handle_buttons">
            <el-button size="small" type="primary" @click="save">确定</el-button>
            <el-button size="small" @click="visible = false">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import { cloneDeep } from "lodash";
    export default {
        methods: {
            init(option) {
                this.$nextTick(() => {
                    this.model = cloneDeep(option);
                });
                this.visible = true;
            },
            save() {
                this.$http.post("/wasteType/update", this.model).then(res => {
                    this.$message.success({
                        message: res.msg
                    });
                    this.visible = false;
                    this.$emit("update");
                });
            }
        },
        data() {
            return {
                visible: false,

                model: {
                    code: "",
                    cName: "",
                    unit: ""
                }
            };
        }
    };
</script>
<style scoped>
    .el-input {
        width: 180px;
        margin-left: 10px;
    }
    .handle_buttons {
        text-align: center;
        margin-top: 10px;
    }
</style>
