<template>
    <div id="log_manage">
        <jy-query ref="form" :model="form" label-width="120px">
            <jy-query-item label="固废类别名称:" prop="cName">
                <el-input placeholder="请输入" v-model="form.cName"></el-input>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('wastesBasicInfoWastesTypeList')">查询</el-button>
                <el-button type="primary" @click="resetForm('form')" v-if="btnexist('wastesBasicInfoWastesTypeReset')">重置</el-button>
            </template>
        </jy-query>
        <jy-table :data="dataList">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column prop="code" label="编码"></jy-table-column>
            <jy-table-column prop="cName" label="固废类别名称"></jy-table-column>
            <jy-table-column prop="unit" label="计算单位"></jy-table-column>
            <jy-operate v-bind:list="operateList" width="200"></jy-operate>
        </jy-table>
        <jy-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageIndex"
            :page-size="pageSize"
            :total="total"
        ></jy-pagination>

        <edit ref="edit" @update="getList"></edit>
    </div>
</template>
<script>
    import { btnMixins } from "@/common/js/button.mixin";
    import Edit from "@/components/pages/admin/basic/rubbishClass/Edit.vue";
    export default {
        data() {
            return {
                form: {
                    cName: ""
                },
                pageSize: 10,
                total: 100,
                pageIndex: 1,
                dataList: [],

                btnMenuId: "wastesBasicInfoWastesType"
            };
        },
        mixins: [btnMixins],
        created() {
            this.getList();
            this.setOperateList();
        },
        methods: {
            setOperateList() {
                let l = [
                    {
                        name: "修改",
                        icon: "el-icon-edit-outline",
                        fun: this.addUpdate,
                        isShow: () => {
                            return this.btnexist("wastesBasicInfoWastesTypeUpdate");
                        }
                    }
                ];
                this.operateList = l;
            },
            getList() {
                let option = {
                    ...this.form,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                };
                let url = "/wasteType/pageQuery";
                this.$http.post(url, option).then(({ detail }) => {
                    console.log(detail);
                    this.dataList = detail.list;
                    this.total = detail.total;
                });
            },
            // 查询表格
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            addUpdate(row) {
                this.$refs.edit.init(row);
            }
        },
        components: {
            Edit
        }
    };
</script>
